var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"fixed-tabs":"","height":"80px","background-color":"transparent","show-arrows":"","slider-color":'primary',"color":'primary'},model:{value:(_vm.selectedTabLocal),callback:function ($$v) {_vm.selectedTabLocal=$$v},expression:"selectedTabLocal"}},[_c('v-tab',{attrs:{"id":"tab-historico"},on:{"click":function($event){_vm.$emit('click:triggered'), _vm.changeTipoListaConsulta('historico')}}},[_c('span',[_c('div',[_c('v-icon',{staticClass:"icon-tab mb-2"},[_vm._v("mdi-history")])],1),_vm._v(" Histórico ")])]),_c('v-tab',{attrs:{"id":"tab-favoritos"},on:{"click":function($event){_vm.$emit('click:triggered'),
          _vm.changeTipoListaConsulta('favoritos', 'Relatórios Favoritos')}}},[_c('span',[_c('div',[_c('v-icon',{staticClass:"icon-tab mb-2"},[_vm._v("mdi-star")])],1),_vm._v(" Favoritos ")])]),_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({attrs:{"id":"tab-modelos"}},'v-tab',attrs,false),on),[_c('span',[_c('div',[_c('v-icon',{staticClass:"icon-tab mb-2"},[_vm._v("mdi-file-cog-outline")])],1),_vm._v(" Modelos ")])])]}}]),model:{value:(_vm.tabModelo),callback:function ($$v) {_vm.tabModelo=$$v},expression:"tabModelo"}},[_c('v-list',_vm._l((_vm.relatoriosModelos),function(grupo,index){return _c('v-list-item',{key:index},[_c('v-menu',{attrs:{"open-on-hover":"","transition":"slide-x-transition","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({style:(!_vm.existeRelatoriosCadastrados(grupo)
                    ? 'cursor: default !important'
                    : '')},'v-list-item-title',attrs,false),on),[_vm._v(" "+_vm._s(grupo.titulo)+" "),(_vm.existeRelatoriosCadastrados(grupo))?_c('v-icon',[_vm._v("mdi-menu-right")]):_vm._e()],1)]}}],null,true)},[(_vm.existeRelatoriosCadastrados(grupo))?_c('v-list',_vm._l((grupo.relatorios_vinculados),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.changeTipoListaConsulta(
                    'modelos',
                    ("Relatórios Modelos :: " + (item.nome)),
                    grupo.id,
                    item.id
                  ),
                    (_vm.tabModelo = !_vm.tabModelo)}}},[_vm._v(" "+_vm._s(item.nome)+" ")])}),1):_vm._e()],1)],1)}),1)],1),(_vm.userIsAdmin)?_c('v-tab',{attrs:{"id":"tab-adicionar-relatorio-modelo","title":"Adicionar relatório modelo"},on:{"click":function($event){return _vm.$router.push('/relatorios-modelos/historico')}}},[_c('span',[_c('div',[_c('v-icon',{staticClass:"icon-tab mb-2"},[_vm._v("mdi-file-plus-outline")])],1),_vm._v(" Novo Modelo ")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }