<template>
  <div>
    <v-tabs
      fixed-tabs
      height="80px"
      background-color="transparent"
      v-model="selectedTabLocal"
      show-arrows
      :slider-color="'primary'"
      :color="'primary'"
    >
      <v-tab
        id="tab-historico"
        @click="$emit('click:triggered'), changeTipoListaConsulta('historico')"
      >
        <span>
          <div>
            <v-icon class="icon-tab mb-2">mdi-history</v-icon>
          </div>
          Histórico
        </span>
      </v-tab>
      <v-tab
        id="tab-favoritos"
        @click="
          $emit('click:triggered'),
            changeTipoListaConsulta('favoritos', 'Relatórios Favoritos')
        "
      >
        <span>
          <div>
            <v-icon class="icon-tab mb-2">mdi-star</v-icon>
          </div>
          Favoritos
        </span>
      </v-tab>
      <v-menu
        transition="slide-y-transition"
        offset-y
        v-model="tabModelo"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            id="tab-modelos"
            v-bind="attrs"
            v-on="on"
          >
            <span>
              <div>
                <v-icon class="icon-tab mb-2">mdi-file-cog-outline</v-icon>
              </div>
              Modelos
            </span>
          </v-tab>
        </template>
        <v-list>
          <v-list-item
            v-for="(grupo, index) in relatoriosModelos"
            :key="index"
          >
            <v-menu
              open-on-hover
              transition="slide-x-transition"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title
                  v-bind="attrs"
                  v-on="on"
                  :style="
                    !existeRelatoriosCadastrados(grupo)
                      ? 'cursor: default !important'
                      : ''
                  "
                >
                  {{ grupo.titulo }}
                  <v-icon v-if="existeRelatoriosCadastrados(grupo)"
                    >mdi-menu-right</v-icon
                  >
                </v-list-item-title>
              </template>
              <v-list v-if="existeRelatoriosCadastrados(grupo)">
                <v-list-item
                  v-for="(item, index) in grupo.relatorios_vinculados"
                  :key="index"
                  @click="
                    changeTipoListaConsulta(
                      'modelos',
                      `Relatórios Modelos :: ${item.nome}`,
                      grupo.id,
                      item.id
                    ),
                      (tabModelo = !tabModelo)
                  "
                >
                  {{ item.nome }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tab
        id="tab-adicionar-relatorio-modelo"
        title="Adicionar relatório modelo"
        v-if="userIsAdmin"
        @click="$router.push('/relatorios-modelos/historico')"
      >
        <span>
          <div>
            <v-icon class="icon-tab mb-2">mdi-file-plus-outline</v-icon>
          </div>
          Novo Modelo
        </span>
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
  props: {
    relatoriosModelos: {
      type: Array,
      default: () => []
    },
    selectedTab: {
      type: Number,
      default: 0
    }
  },
  computed: {
    selectedTabLocal: {
      get() {
        return this.selectedTab;
      },
      set(newValue) {
        this.$emit('update:selectedTab', newValue);
      }
    }
  },
  data: () => ({
    tabModelo: false,
    userIsAdmin: false
  }),
  mounted() {
    this.getUserIsAdmin();
  },
  methods: {
    getUserIsAdmin() {
      AuthService.userIsAdmin().then(
        (userIsAdmin) => (this.userIsAdmin = userIsAdmin)
      );
    },
    existeRelatoriosCadastrados(grupo) {
      return grupo.relatorios_vinculados.length;
    },
    changeTipoListaConsulta(
      tipo,
      titulo = 'Relatórios',
      grupoId = null,
      relatorioModeloId = null
    ) {
      this.$store.dispatch('setTipoListaRelatorio', {
        tipo: tipo,
        titulo: titulo,
        grupo: grupoId,
        relatorio_modelo_id: relatorioModeloId
      });
    }
  }
};
</script>
